import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  title: "Notifications"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "badge badge-circle badge-secondary position-relative",
  style: {"top":"5px","right":"12px"}
}
const _hoisted_5 = {
  class: "d-flex align-items-center mx-1 mx-lg-3",
  title: "Accueil"
}
const _hoisted_6 = { class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px",
        disabled: _ctx.isOnline
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["svg-icon svg-icon-1", _ctx.getIconClass]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'notifications' })))
        }, [
          _createVNode(_component_inline_svg, {
            src: _ctx.getBellIcon,
            active: true
          }, null, 8, ["src"])
        ], 2)
      ], 8, _hoisted_3)
    ]),
    (_ctx.notificationCount)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.notificationCount), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: "svg-icon svg-icon-1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'homepage' })))
        }, [
          _createVNode(_component_inline_svg, {
            src: require('@/../public/media/icons/duotune/general/gen001.svg')
          }, null, 8, ["src"])
        ])
      ])
    ])
  ]))
}