
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useStore } from "vuex";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    const notificationCount = computed(
      () => store.getters.getNotificationCount
    );

    const openAgribestLink = () => {
      openLinkAgriBest(process.env.VUE_APP_ILLUSTRATED_URL);
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
      notificationCount,
      openLinkAgriBest,
      openAgribestLink,
    };
  },
});
